<template>
	<b-modal ref="modal" hide-footer>
		<template v-slot:modal-title>
			{{ $t("invoice.devis.add_devis") }}
		</template>

		<div class="row">
			<div class="col-6">
				<div class="mb-2">
					<label class="mb-1" for="tiers_id">{{ $t('tiers.billing_entity') }} *</label>
					<e-select
						v-model="quotation.author"
						id="entity_id"
						track-by="tiers_id"
						label="tiers_rs"
						:placeholder="getTrad('tiers.rechercher_billing_entity')"
						:selectedLabel="getTrad('global.selected_label')"
						:options="entity"
						:searchable="true"
						:allow-empty="false"
						:loading="isLoadingEntity"
						:show-labels="false"
						:class="{ 'is-invalid': errors && errors.indexOf('author') > -1 }"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>
			</div>

			<div class="col-6">
				<div class="mb-2">
					<label class="mb-1" for="tiers_id">{{ $t('tiers.tiers') }} *</label>
					<e-select
						v-model="quotation.tiers"
						track-by="tiers_id"
						label="tiers_rs"
						:placeholder="this.getTrad('tiers.rechercher')"
						:selectedLabel="getTrad('global.selected_label')"
						:options="tiers"
						:searchable="true"
						:allow-empty="false"
						:loading="isLoadingTiers"
						:show-labels="false"
						:class="{ 'is-invalid': errors && errors.indexOf('tiers') > -1 }"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }} {{ option.tiers_postalcode }}</template>
						<template slot="option" slot-scope="{ option }">{{ option.tiers_rs }} {{ option.tiers_postalcode }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>
			</div>

			<div class="col-6">
				<div class="mb-2">
					<label class="mb-1" for="num_dossier">{{ $t('invoice.devis.num_dossier') }}</label>
					<input type="text" name="num_dossier" class="form-control" v-model="quotation.num_dossier">
				</div>
			</div>

			<div class="col-6">
				<div class="mb-2">
					<label class="mb-1" for="ref_client">{{ $t('invoice.devis.ref_client') }}</label>
					<input type="text" name="ref_client" class="form-control" v-model="quotation.ref_client">
				</div>
			</div>

			<div class="col-12">
				<div class="mb-2">
					<label class="mb-1" for="created_date">{{ $t('invoice.devis.created_date') }}</label>
					<e-datepicker v-model="quotation.created_date" id="created_date" ></e-datepicker>
				</div>
			</div>

			<div class="col-12">
				<div class="mb-2">
					<label class="mb-1" for="commentaire">{{ $t('invoice.commentaire') }}</label>
					<textarea id="commentaire" class="form-control" rows="3" v-model="quotation.description"></textarea>
				</div>
			</div>

			<div class="col-12 text-center">
				<b-button variant="primary" @click="validForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['far', 'plus']" /> {{ $t('global.ajouter') }}</b-button>
			</div>
		</div>
	</b-modal>
</template>

<script type="text/javascript">
    import Quotation from "@/mixins/Quotation.js"
    import Tiers from "@/mixins/Tiers.js"

	export default {
		name: 'AddDevis',
		mixins: [Quotation, Tiers],
		props: ['tiers_id'],
		data () {
			return {
				entity: [],
				isLoadingEntity: false,
				tiers: [],
				isLoadingTiers: false,
				processing: false,
				quotation: {
					author: null,
					tiers: null,
					num_dossier: '',
					ref_client: '',
					description: '',
					created_date: new Date()
				},
				errors: []
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.isLoadingEntity = true
				this.entity = await this.loadTiersEntity()
				this.isLoadingEntity = false
				this.isLoadingTiers = true
				this.tiers = await this.loadTiers()
				this.isLoadingTiers = false

				if(this.entity.length > 0) {
					this.quotation.author = this.entity[0]
				}
				if(this.tiers_id) {
					this.quotation.tiers = this.tiers.find(tier => tier.tiers_id == this.tiers_id)
				}
			},

			openModal() {
                this.$refs.modal.show()
			},

			async validForm() {
				this.processing = true
				this.errors = []

				if(!this.quotation.author) {
					this.errors.push('author')
				}
				if(!this.quotation.tiers) {
					this.errors.push('tiers')
				}

				if(this.errors.length > 0) {
					this.processing = false
					return false
				}

				const params = {
					author: this.quotation.author.tiers_id,
					tiers: this.quotation.tiers.tiers_id,
					num_dossier: this.quotation.num_dossier,
					ref_client: this.quotation.ref_client,
					description: this.quotation.description,
					created_date: this.quotation.created_date
				}

				const quotation = await this.createQuotation(params)
				this.$router.push({ name: 'EditDevis', params: {quotation_id: quotation.retour.quotation_id}})
				this.processing = false
			}
		}
	}

</script>